.dialog-form .fields {
  display: inline-block;
  margin: 0 auto;
  text-align: left;
}
.dialog-form .field {
  margin-bottom: 20px;
}
.dialog-form label {
  float: left;
  display: inline-block;
  width: 142px;
  vertical-align: middle;
  text-align: right;
  padding-top: 4px;
  padding-right: 1em;
}
@media (max-width: 400px) {
  .dialog-form label {
    float: none;
    text-align: left;
  }
}
.dialog-form .controls {
  white-space: nowrap;
  margin-left: 150px;
}
@media (max-width: 400px) {
  .dialog-form .controls {
    margin-left: 0;
  }
}
.dialog-form input[type=text], .dialog-form input[type=password], .dialog-form input[type=email] {
  width: 200px;
}
.dialog-form .error input[type=text], .dialog-form .error input[type=password], .dialog-form .error input[type=email] {
  border-color: #c4443b;
}
.dialog-form .required-field-marker {
  width: 20px;
  color: #c4443b;
}
.dialog-form .submit-div {
  text-align: center;
  margin-bottom: 1em;
}
.dialog-form input[type=submit] {
  font-size: 120%;
  color: #fff;
  border: none;
  background: #f0662f;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 5px;
  cursor: pointer;
}
.dialog-form input[type=submit]:hover {
  background: #f3895f;
}
.dialog-form .error-text, .dialog-form .help-text {
  margin-left: 150px;
  font-size: 80%;
}
.dialog-form .error-text {
  color: #c4443b;
}
.dialog-form.error .help-text {
  display: none;
}
.dialog-form .errors {
  color: #c4443b;
}
.dialog-form .clear {
  clear: both;
}
.dialog-form .password-with-eye input {
  width: 171px;
}
.dialog-form .password-with-eye .eye-button {
  display: inline-block;
  width: 28px;
  height: 27px;
  line-height: 27px;
  border: 1px solid #b9b5b2;
  border-left: none;
  text-align: center;
  vertical-align: bottom;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-image: url(./eye.svg);
  background-position: center;
  background-repeat: no-repeat;
}
.dialog-form .password-with-eye .eye-button:active {
  background-color: #ccc;
}

ul, ol {
  padding-left: 30px;
}

.login-form {
  text-align: center;
  line-height: 1.4;
}
.login-form input[type=text], .login-form input[type=password] {
  margin-bottom: 10px;
  font-size: 120%;
  width: 200px;
  text-align: inherit;
  padding: 7px;
  border-width: 1px;
  border-color: #c0c0c0;
  border-radius: 5px;
  border-style: solid;
}
.login-form input[type=text]:focus, .login-form input[type=password]:focus {
  padding: 6px;
  border-width: 2px;
  border-color: #03abcc;
}
.login-form .login-eye-button {
  display: inline-block;
  width: 30px;
  text-align: center;
  vertical-align: bottom;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  right: 30px;
  z-index: 10;
  position: absolute;
  height: 41px;
  background-image: url(eye.svg);
  background-position: center;
  background-repeat: no-repeat;
}
.login-form .login-eye-button:active {
  background-color: #03abcc;
  height: 41px;
  border-radius: 0 5px 5px 0;
}
.login-form .input-with-placeholder {
  position: relative;
}
.login-form .error input[type=text], .login-form .error input[type=password] {
  border-color: #c4443b;
}
.login-form input[type=submit], .login-form a.demo {
  font-size: 130%;
  color: #fff;
  border: none;
  border-radius: 3px;
  background: #03abcc;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-appearance: none;
}
.login-form input[type=submit]:hover, .login-form a.demo:hover {
  background: #07d3fb;
}
.login-form a.demo {
  display: inline-block;
  text-decoration: none;
  font-size: 100%;
  margin-bottom: 20px;
  border: 1px solid #03abcc;
  background: #fff;
  color: #03abcc;
}
.login-form a.demo:hover {
  background: rgba(3, 171, 204, 0.1);
}
.login-form .error-message {
  color: #c4443b;
  margin-bottom: 5px;
}
.login-form .register-link {
  font-size: 120%;
  line-height: 2;
}
.login-form .forgot-link {
  line-height: 2;
}
.login-form .forgot-link a {
  color: #888;
}

.register-form {
  width: 380px;
  color: #777;
}
.register-form h1 {
  text-align: center;
  margin-bottom: 30px;
}

.messages-dialog {
  width: 400px;
  color: #444;
}
.messages-dialog.success {
  color: #282;
}
.messages-dialog.error {
  color: #c4443b;
}
.messages-dialog p {
  margin-bottom: 0.7em;
}

.forgot-form .error {
  color: #c4443b;
}
.forgot-form .error input[type=text], .forgot-form .error input[type=password] {
  border-color: #c4443b;
}
.forgot-form .forgot-form-found-users {
  list-style-type: none;
  padding: 0 20px;
  margin: 0;
  line-height: 2;
}
.forgot-form .forgot-form-found-users li {
  float: left;
  padding: 10px 0;
}
.forgot-form .forgot-form-found-users li a {
  padding: 20px;
}

.new-password-form h1 {
  text-align: center;
}

.minipage-background, html {
  background-color: #999;
}
@media (prefers-color-scheme: dark) {
  .minipage-background, html {
    background-color: #333;
  }
}
.minipage-background:before, html:before {
  content: " ";
  position: absolute;
  z-index: -2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: url(./backgrounds/minipage-bg-autoscan.svg);
}
@media (prefers-color-scheme: dark) {
  .minipage-background:before, html:before {
    opacity: 0.1;
  }
}

html {
  height: auto;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
html * {
  box-sizing: border-box;
}

body {
  position: relative;
  max-width: 630px;
  padding: 35px 20px 35px;
  margin: 20px 10px;
  background: #fff;
  height: auto;
  -ms-box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
  -o-box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
  font-size: 16px;
  border-top: 6px solid #03abcc;
}
@media (min-width: 1024px) {
  body {
    padding: 35px 65px 35px;
  }
}
body.narrow {
  max-width: 480px;
}

h1 {
  font-size: 175%;
  color: #03abcc;
  font-weight: 100;
  margin-bottom: 1em;
  margin-top: 1em;
}
h1:first-child {
  margin-top: 0;
}

h2 {
  font-size: 140%;
  color: #03abcc;
  font-weight: 100;
  margin-bottom: 0.5em;
}

h3 {
  font-size: 120%;
  color: #03abcc;
  font-weight: 100;
  margin-bottom: 0.5em;
}

hr {
  margin: 40px 0;
  border: none;
  border-top: 1px solid #999;
}

p {
  margin-bottom: 20px;
}

li {
  margin-bottom: 1em;
}

tt {
  font-family: monospace;
  font-size: 90%;
  background: #e8e8e8;
  padding: 0 3px;
}

.warning {
  color: #c4443b;
}

.home-link {
  color: #aaa;
  display: block;
  margin-bottom: 1em;
}