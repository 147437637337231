@font-face {
  font-family: PTRouble;
  src: url("fonts/PTRouble-Normal.eot");
  src: local("_unexisting_"), url("fonts/PTRouble-Normal.woff") format("woff"), url("fonts/PTRouble-Normal.ttf") format("truetype"), url("fonts/PTRouble-Normal.svg#PTRoubleSans") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: PTRouble;
  src: url("fonts/PTRouble-Bold.eot");
  src: local("_unexisting_"), url("fonts/PTRouble-Bold.woff") format("woff"), url("fonts/PTRouble-Bold.ttf") format("truetype"), url("fonts/PTRouble-Bold.svg#PTRoubleSans") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: PTRouble;
  src: url("fonts/PTRouble-Italic.eot");
  src: local("_unexisting_"), url("fonts/PTRouble-Italic.woff") format("woff"), url("fonts/PTRouble-Italic.ttf") format("truetype"), url("fonts/PTRouble-Italic.svg#PTRoubleSans") format("svg");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: PTRouble;
  src: url("fonts/PTRouble-Bold-Italic.eot");
  src: local("_unexisting_"), url("fonts/PTRouble-Bold-Italic.woff") format("woff"), url("fonts/PTRouble-Bold-Italic.ttf") format("truetype"), url("fonts/PTRouble-Bold-Italic.svg#PTRoubleSans") format("svg");
  font-weight: bold;
  font-style: italic;
}
.rouble {
  font-family: PTRouble;
  text-transform: uppercase;
}