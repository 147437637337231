select {
  padding: 2px;
  border: 1px solid #b8b5b2;
  background: #ffffff;
  color: #222;
  transition: 0.2s border-color;
  outline: none;
}
select.error, select:invalid {
  border-color: #c4443b;
}
select:disabled {
  background: #eceaeb;
  color: #b9b5b2;
}
select:focus {
  border-color: #7192be;
  transition: 0.1s border-color;
  -ms-box-shadow: 0 0 3px rgba(113, 146, 190, 0.4) inset;
  -o-box-shadow: 0 0 3px rgba(113, 146, 190, 0.4) inset;
  box-shadow: 0 0 3px rgba(113, 146, 190, 0.4) inset;
}