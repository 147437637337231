.mui_basedialog input[type=text], .mui_basedialog input[type=password], .mui_basedialog input[type=email], .mui_basedialog input[type=search], .mui_basedialog input[type=number], .mui_basedialog input[type=tel], .mui_basedialog textarea {
  border: 1px solid #b9b5b2;
  padding: 3px 5px;
  width: 150px;
  background-color: #fff;
  color: #222;
  transition: 0.2s border-color;
  outline: none;
}
.mui_basedialog input[type=text].error, .mui_basedialog input[type=text]:invalid:not(.ignore-invalid), .mui_basedialog input[type=password].error, .mui_basedialog input[type=password]:invalid:not(.ignore-invalid), .mui_basedialog input[type=email].error, .mui_basedialog input[type=email]:invalid:not(.ignore-invalid), .mui_basedialog input[type=search].error, .mui_basedialog input[type=search]:invalid:not(.ignore-invalid), .mui_basedialog input[type=number].error, .mui_basedialog input[type=number]:invalid:not(.ignore-invalid), .mui_basedialog input[type=tel].error, .mui_basedialog input[type=tel]:invalid:not(.ignore-invalid), .mui_basedialog textarea.error, .mui_basedialog textarea:invalid:not(.ignore-invalid) {
  border-color: #c4443b;
}
.mui_basedialog input[type=text]:disabled, .mui_basedialog input[type=password]:disabled, .mui_basedialog input[type=email]:disabled, .mui_basedialog input[type=search]:disabled, .mui_basedialog input[type=number]:disabled, .mui_basedialog input[type=tel]:disabled, .mui_basedialog textarea:disabled {
  background: #eceaeb;
  color: #b9b5b2;
}
.mui_basedialog input[type=text]:focus, .mui_basedialog input[type=password]:focus, .mui_basedialog input[type=email]:focus, .mui_basedialog input[type=search]:focus, .mui_basedialog input[type=number]:focus, .mui_basedialog input[type=tel]:focus, .mui_basedialog textarea:focus {
  border-color: #7192be;
  transition: 0.1s border-color;
  -ms-box-shadow: 0 0 3px rgba(113, 146, 190, 0.4) inset;
  -o-box-shadow: 0 0 3px rgba(113, 146, 190, 0.4) inset;
  box-shadow: 0 0 3px rgba(113, 146, 190, 0.4) inset;
}