html, body {
  height: 100%;
  margin: 0;
  position: relative;
}

body {
  font-family: SegoeUI, Segoe UI, Trebuchet MS, Helvetica, Tahoma, Arial, Verdana, sans-serif;
  font-size: 14px;
  color: #222;
  line-height: 1.3;
}

input, textarea, select, button {
  font-family: SegoeUI, Segoe UI, Trebuchet MS, Helvetica, Tahoma, Arial, Verdana, sans-serif;
  font-size: 100%;
  max-width: 100%;
}

h1, h2, h3, h4, h5 {
  margin-top: 0;
  font-weight: normal;
}

label {
  -ms-user-select: none;
      user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
}

a {
  color: #03abcc;
  text-decoration: underline;
}
a:hover, a:focus, a.mui_link_hover {
  color: #f0662f;
}