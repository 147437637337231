button:not([class*=MuiButtonBase-root]), input[type=submit], .mui_button {
  display: inline-block;
  background: #eceaeb;
  border: 2px solid #03abcc;
  padding: 1px 16px;
  margin: 0;
  text-decoration: none;
  cursor: default;
  color: #3f3f3f;
}
button:not([class*=MuiButtonBase-root]).warning, input[type=submit].warning, .mui_button.warning {
  border-color: #c4443b;
}
button:not([class*=MuiButtonBase-root]):hover, input[type=submit]:hover, .mui_button:hover {
  color: #3f3f3f;
}
button:not([class*=MuiButtonBase-root]):active, button:not([class*=MuiButtonBase-root]).mui_button_active, input[type=submit]:active, input[type=submit].mui_button_active, .mui_button:active, .mui_button.mui_button_active {
  background: #03abcc;
  color: #ffffff;
}
button:not([class*=MuiButtonBase-root]):active.warning, button:not([class*=MuiButtonBase-root]).mui_button_active.warning, input[type=submit]:active.warning, input[type=submit].mui_button_active.warning, .mui_button:active.warning, .mui_button.mui_button_active.warning {
  background: #c4443b;
}
button:not([class*=MuiButtonBase-root])[disabled="1"], button:not([class*=MuiButtonBase-root])[disabled=disabled], button:not([class*=MuiButtonBase-root]):disabled, input[type=submit][disabled="1"], input[type=submit][disabled=disabled], input[type=submit]:disabled, .mui_button[disabled="1"], .mui_button[disabled=disabled], .mui_button:disabled {
  border-color: #b7b6b2;
  background: #fff;
  color: #b9b5b2;
}
button:not([class*=MuiButtonBase-root])[disabled="1"]:active, button:not([class*=MuiButtonBase-root])[disabled=disabled]:active, button:not([class*=MuiButtonBase-root]):disabled:active, input[type=submit][disabled="1"]:active, input[type=submit][disabled=disabled]:active, input[type=submit]:disabled:active, .mui_button[disabled="1"]:active, .mui_button[disabled=disabled]:active, .mui_button:disabled:active {
  background: #fff;
}
button:not([class*=MuiButtonBase-root])::-moz-focus-inner, input[type=submit]::-moz-focus-inner, .mui_button::-moz-focus-inner {
  border: 0;
}
button:not([class*=MuiButtonBase-root]):active, button:not([class*=MuiButtonBase-root]):focus, input[type=submit]:active, input[type=submit]:focus, .mui_button:active, .mui_button:focus {
  outline: none;
}