@font-face {
  font-family: SegoeUI;
  src: url("fonts/SegoeUI-Regular-subset.eot");
  src: local("Segoe UI"), url("fonts/SegoeUI-Regular-subset.woff") format("woff"), url("fonts/SegoeUI-Regular-subset.ttf") format("truetype"), url("fonts/SegoeUI-Regular-subset.svg#SegoeUI") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: SegoeUI;
  src: url("fonts/SegoeUI-Light-subset.eot");
  src: local("Segoe UI Light"), url("fonts/SegoeUI-Light-subset.woff") format("woff"), url("fonts/SegoeUI-Light-subset.ttf") format("truetype"), url("fonts/SegoeUI-Light-subset.svg#SegoeUI-Light") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: SegoeUI;
  src: url("fonts/SegoeUI-Semibold-subset.eot");
  src: local("Segoe UI Semibold"), url("fonts/SegoeUI-Semibold-subset.woff") format("woff"), url("fonts/SegoeUI-Semibold-subset.ttf") format("truetype"), url("fonts/SegoeUI-Semibold-subset.svg#SegoeUI-Semibold") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: SegoeUI;
  src: url("fonts/SegoeUI-Italic-subset.eot");
  src: local("Segoe UI Italic"), local("Segoe UI"), url("fonts/SegoeUI-Italic-subset.woff") format("woff"), url("fonts/SegoeUI-Italic-subset.ttf") format("truetype"), url("fonts/SegoeUI-Italic-subset.svg#SegoeUI-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
}