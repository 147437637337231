:not(#foo) input[type=radio] {
  position: absolute;
  opacity: 0;
  z-index: 1;
  margin: 0;
  margin-top: 1px;
  cursor: pointer;
}
:not(#foo) input[type=radio] + span.mui_radiobutton {
  background: url(./images/radiobutton.svg) 0 0 no-repeat;
  position: absolute;
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-top: 3px;
  margin-right: 4px;
}
:not(#foo) input[type=radio] + span.mui_radiobutton + label {
  padding-left: 18px;
  cursor: pointer;
}
:not(#foo) input[type=radio]:checked + span.mui_radiobutton {
  background-position: 0 -14px;
}
:not(#foo) input[type=radio]:disabled + span.mui_radiobutton {
  background-position: 0 -28px;
}
:not(#foo) input[type=radio]:disabled:checked + span.mui_radiobutton {
  background-position: 0 -42px;
}