.clearfix {
  zoom: 1;
}
.clearfix:before, .clearfix:after {
  content: ".";
  visibility: hidden;
  display: block;
  height: 0;
  overflow: hidden;
}
.clearfix:after {
  clear: both;
}